.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.title {
  padding: 25px;
}

.cards-wrap {
  display: block;
  grid-gap: 15px;
}


.card {
  border-radius: 15px;
  box-shadow: 0px 0px 10px grey;
  text-decoration: none;
  color: black;
  width: 50vw;
  margin: 10px;
  padding: 25px;
  overflow-wrap: anywhere;
  background-color: white;
  max-width: 350px;
  display: inline-grid;
}

.card:hover {
  color: #af6cff !important;
}

#dark:checked ~ #root .card {
  color: white;
  background-color: #010024db;
}

.ignore {
  margin: 15px auto;
  padding: 0.5px;
  max-width: 350px;
  background-color: #8080801c;
  border-radius: 15px;

}

#dark {
  display: none;
}

[for="dark"] {
  display: flex;
  margin: 15px auto;
  width: min-content;
  background-color: white;
  color: black;
  padding: 5px;
  border-radius: 10px;
  border-style: outset;
  user-select: none;
}

[for="dark"]:active {
  border-style: inset;
}

